import { useState, useEffect, useRef, useCallback } from "react"
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../../hooks/firebase";
import { query, orderBy, collection, getDocs, Timestamp} from "firebase/firestore"
import { donationMetaData, fsColNames } from "../../constants"
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

const DonationReport = () => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
    } else {
      // User is signed out
      window.location.href = "/login"
    }
  });

  const donationGridRef = useRef<AgGridReact<any>>(null);
  const sumGridRef = useRef<AgGridReact<any>>(null);

  const donationColumnDefs = [
    { field: 'causeTitle' },
    { field: 'email',},
    { field: 'amount', valueFormatter: (row:any) => `$${row.value}`},
    { field: 'createdAt', flex: 2},
  ];

  const sumsColumnDefs = [
    { field: 'causeType'},
    { field: 'totalDonatedAmount', flex: 2, valueFormatter: (row:any) => `$${row.value}`},
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
  }

  const [analyticsList, setAnalyticsList] = useState<any>(null)
  const [donations, setDonations] = useState<any>(null)
  const [donationTotalPerCategory, setDonationTotalPerCategory] = useState<any>(null)

  useEffect(() => {
    getAnalyticsData()
    getDonations()
  }, [])

  const getMetaByCauseType = (cause: string) => {
    return donationMetaData.find(item => item.id == cause)
  }

  const getAnalyticsData = async () => {
    const q = query(collection(db, fsColNames.donationsAnalytics), orderBy("order"))
    const querySnapshot = await getDocs(q)

    const docDatas: any = []
    const sumDatas: any = []

    querySnapshot.forEach((doc) => {
      const daData = doc.data()

      // prepare analytics list data
      docDatas.push({
        id: doc.id,
        detail: daData,
      })

      const cause = getMetaByCauseType(doc.id)
      sumDatas.push({
        id: doc.id,
        causeType: cause?.causeTitle,
        totalDonatedAmount: daData.totalGiftCount * daData.targetFundForOneGift + daData.donatedFundForOneGift
      })
    })

    setDonationTotalPerCategory(sumDatas)
    setAnalyticsList(docDatas)
  }

  const getDonations = async () => {
    const q = query(collection(db, fsColNames.donations))
    const querySnapshot = await getDocs(q)

    const docDatas: any = []

    querySnapshot.forEach((doc) => {
      const daData = doc.data()

      const cause = getMetaByCauseType(daData.type)
      docDatas.push({
        id: doc.id,
        causeTitle: cause?.causeTitle,
        email: daData.email,
        amount: daData.amount,
        createdAt: new Date(daData.createdAt.seconds*1000),
      })
    })

    setDonations(docDatas)
  }

  const logout = () => {
    signOut(auth);
    window.location.href = "/login"
  };

  const donationsExport = useCallback(() => {
    if (donationGridRef.current != null && donationGridRef.current.api != null) {
      donationGridRef.current?.api.exportDataAsCsv();
    }
  }, []);

  const sumExport = useCallback(() => {
    if (sumGridRef.current != null && sumGridRef.current.api != null) {
      sumGridRef.current?.api.exportDataAsCsv();
    }
  }, []);

  return (
    <div className="p-4 pb-8 px-8">
      <div className="text-right p-4 flex justify-between">
        <div></div>
        <h1 className="text-4xl text-slate-700 font-bold text-center">Donation Report</h1>
        <button type="submit" onClick={logout} className="text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
          Sign out
        </button>
      </div>


      <div>
        <div className="flex my-2 items-end">
          <h1 className="text-2xl text-slate-600 font-bold">Donations</h1>
          <button type="submit" onClick={donationsExport} className="ml-4 text-white bg-sky-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
            Export CSV
          </button>
        </div>
        <div className="ag-theme-alpine" style={{ width: "100%", height: "500px" }}>
          <AgGridReact
            ref={donationGridRef}
            rowData={donations}
            columnDefs={donationColumnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            pagination={true}
            paginationPageSize={10}
            alwaysShowVerticalScroll={true}
            domLayout={'autoHeight'}
            suppressExcelExport={true}
          />
        </div>
      </div>

      <div>
        <div className="flex my-2 items-end mt-[50px]">
          <h1 className="text-2xl text-slate-600 font-bold">Total Donation Per Cause</h1>
          <button type="submit" onClick={sumExport} className="ml-4 text-white bg-sky-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
            Export CSV
          </button>
        </div>
        <div className="ag-theme-alpine" style={{ width: "100%"}}>
          <AgGridReact
            ref={sumGridRef}
            rowData={donationTotalPerCategory}
            columnDefs={sumsColumnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            domLayout={'autoHeight'}
            suppressExcelExport={true}
          />
        </div>
      </div>
    </div>
  )
}

export default DonationReport
