import SuccessIcon from "../assets/imgs/icon-success.svg"
import ErrorIcon from "../assets/imgs/icon-error.svg"

import { Modal, Spinner } from "flowbite-react"

export enum PaymentModalType {
  Loading = "Loading",
  TapCard = "TapCard",
  Error = "Error",
  Success = "Success",
}

export interface IPaymentModalProps {
  open: boolean
  type?: PaymentModalType
  message?: string
}

const PaymentModal = ({
  open = false,
  type = PaymentModalType.Loading,
  message = "",
}: IPaymentModalProps) => {
  let content = null

  switch (type) {
    case PaymentModalType.Loading:
      content = (
        <div role='status' className='m-auto text-center'>
          <Spinner
            aria-label='Extra large spinner example'
            className='w-[6rem!important] h-[6rem!important] fill-[#ff6b00!important]'
            size='xl'
          />
          <p className='text-4xl mt-8'>Loading</p>
        </div>
      )
      break
    case PaymentModalType.TapCard:
      content = (
        <>
          <div role='status' className='m-auto text-center'>
            <Spinner
              aria-label='Extra large spinner example'
              className='w-[6rem!important] h-[6rem!important] fill-[#ff6b00!important]'
              size='xl'
            />
            <p className='text-4xl mt-8'>Please tap your card on the terminal</p>
          </div>
        </>
      )
      break
    case PaymentModalType.Error:
      content = (
        <div className='text-center'>
          <img src={ErrorIcon} className='m-auto w-24 h-24' />
          <p className='mt-8 text-3xl'>
            {message != "" ? `: ${message}` : "Processing error."}
            <br />
            <br />
            Please try again.
          </p>
        </div>
      )
      break
    case PaymentModalType.Success:
      content = (
        <div className='text-center'>
          <img src={SuccessIcon} className='m-auto w-24 h-24' />
          <p className='mt-8 text-3xl'>
            Thank you!
            <br />
            Payment complete
          </p>
        </div>
      )
      break
  }

  return (
    <Modal show={open} position='bottom-center' className=''>
      <Modal.Body className='pt-12 pb-12 mb-[350px]'>
        <section className='payment'>{content}</section>
      </Modal.Body>
    </Modal>
  )
}

export default PaymentModal
