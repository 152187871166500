import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore"

import { db } from "../hooks/firebase"
import { donationMetaData, fsColNames } from "../constants"

import GiftBox from "../assets/imgs/Gift_Box.png"
import { useStore } from "../hooks/store"

interface ScreenCardProps {
  id?: string
  detail: {
    id: string
    background: string
    fundImg: string
    title: string
    iseeText: React.ReactNode
  }
}

const ScreenCard = ({ detail }: ScreenCardProps) => {
  const { id, background, fundImg, title, iseeText } = detail
  const [analytics, setAnalytics] = useState<any | null>(null)
  const [progress, setProgress] = useState<any>(0)
  const [helpTarget, setHelpTarget] = useState<any>(0)

  const kiosk = useStore((state) => state.kiosk)

  useEffect(() => {
    getAnalyticsData()
  }, [])

  const getAnalyticsData = async () => {
    const docRef = doc(db, fsColNames.donationsAnalytics, id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()
      setAnalytics(data)
      setProgress(Math.round((data.donatedFundForOneGift / data.targetFundForOneGift) * 100))
      setHelpTarget(data.totalGiftCount + 1)
    } else {
      const foundMeta = donationMetaData.find((md) => md.id == id)
      console.log("No such document. foundMeta", foundMeta)

      await setDoc(docRef, {
        order: foundMeta?.order,
        causeTitle: foundMeta?.causeTitle,
        overviewTitle: foundMeta?.overviewTitle,
        targetFundForOneGift: foundMeta?.fundForOneGift,
        donationCount: 0,
        totalGiftCount: 0,
        donatedFundForOneGift: 0,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      })

      getAnalyticsData()
    }
  }

  return (
    <Link to={`/${kiosk}/cause-selection`}>
      <div className='screen-card' key={id}>
        <div className='h-[795px] pt-8'>
          <img className='m-auto mt-2 h-[325px]' src={fundImg} alt={id} />

          <h3 className='mt-2 mb-0 text-[63px] font-black text-center'>{title}</h3>
          <p className='text-[27px] font-black text-center'>
            <b className='text-[#ff6b00]'>{analytics?.totalGiftCount}</b> full gifts sent to date.
            Help us make it
            <b className='text-[#ff6b00]'> {helpTarget}</b>!
          </p>

          <div className='relative top-[28px]'>
            <div className='pin2'>
              <span
                className='text-[43px] font-black text-[#ff6b00] absolute top-[50%] left-[50%]'
                style={{ transform: "translate(-50%, -50%)" }}
              >
                {helpTarget}
              </span>
            </div>

            <div className='w-[795px] m-auto mt-8 h-[50px] bg-gray-200 rounded-full dark:bg-gray-700'>
              <div
                className='h-[50px] bg-[#f36b21] rounded-full dark:bg-[#f36b21]'
                style={{ width: progress + "%" }}
              ></div>
            </div>
          </div>

          <div className='text-center relative top-[126px]'>
            <button
              type='button'
              className='text-white bg-[#ff6b00] hover:bg-[#ff6b00] font-medium rounded-full text-[70px] pl-[70px] pr-[350px] py-8 text-center m-auto '
            >
              Donate now
              <img
                className='absolute top-[-60px] right-[160px] w-[340px]'
                src={GiftBox}
                alt={"gift box"}
              />
            </button>
          </div>
        </div>
        <div
          className={"h-[1128px] bg-top bg-cover"}
          style={{ backgroundImage: "url(" + background + ")" }}
        >
          {iseeText}
        </div>
      </div>
    </Link>
  )
}

export default ScreenCard
