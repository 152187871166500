// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

// config
// const firebaseConfig = {
//   apiKey: "AIzaSyDMDrnbrCIdgKpxOBsxQ6VRU90kOijwjUQ",
//   authDomain: "donationapp-6728e.firebaseapp.com",
//   projectId: "donationapp-6728e",
//   storageBucket: "donationapp-6728e.appspot.com",
//   messagingSenderId: "226379306003",
//   appId: "1:226379306003:web:dad9edf539fa71f587dbe6",
//   measurementId: "G-JMBZV18Q7Q",
// }

const firebaseConfig = {
  apiKey: "AIzaSyBxnxT7Fx96KjMXbBVhwqBseHHK1_SSe58",
  authDomain: "worldvision-stripe-kiosk-2022.firebaseapp.com",
  projectId: "worldvision-stripe-kiosk-2022",
  storageBucket: "worldvision-stripe-kiosk-2022.appspot.com",
  messagingSenderId: "986728618534",
  appId: "1:986728618534:web:c26adaa8bdbf78708a24e7",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app);
const db = getFirestore(app)

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  db,
  auth,
  logInWithEmailAndPassword,
  logout, 
};
