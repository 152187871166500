import React from "react"
import Header from "./partials/Header"
import Footer from "./partials/Footer"

interface MasterLayoutProps {
  children?: React.ReactNode
}

const MasterLayout = ({ children }: MasterLayoutProps) => {
  return (
    <div className='all-content w-[1080px] h-[1920px]'>
      {/* <Header /> */}
      <div className='body-container w-[1080px] h-[1920px]'>{children}</div>

      <Footer />
    </div>
  )
}

export default MasterLayout
