import { Link, useParams } from "react-router-dom"
import GiftBox from "../assets/imgs/Gift_Box.png"
import WorldVisionImg from "../assets/imgs/world-vision.png"
import { useStore } from "../hooks/store"

const ScreenCardEnd = () => {
  const kiosk = useStore((state) => state.kiosk)

  return (
    <Link to={`/${kiosk}/cause-selection`}>
      <div className='w-[1080px] h-[1920px] px-[110px] pt-[180px]'>
        <h1 className='text-[283px] text-[#ff6b00] leading-none'>What is your world vision?</h1>
        <button
          type='button'
          className='bg-[#ff6b00] mt-20 focus:ring-4 focus:outline-none focus:ring-gray-300 w-full rounded-full text-[65px] pl-[100px] py-8 text-left text-white'
        >
          Get started
          <img
            className='absolute w-[340px] mt-[-175px] ml-[390px]'
            src={GiftBox}
            alt={"gift box"}
          />
        </button>
        <img className='m-auto mt-[226px]' src={WorldVisionImg} alt={"gift box"} />
      </div>
    </Link>
  )
}

export default ScreenCardEnd
