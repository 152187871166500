import Plausible from "plausible-tracker"

const { trackEvent, enableAutoPageviews } = Plausible({
  domain: process.env.REACT_APP_PLAUSIBLE_DOMAIN,
  trackLocalhost: false,
})

export { trackEvent, enableAutoPageviews }

export default trackEvent
