import { useNavigate, useParams } from "react-router-dom"
import { useStore } from "../hooks/store"
import { trackEvent } from "../utils/analytics"

interface DonationItemProps {
  id?: string
  fundImgClass?: string
  detail: {
    id: string
    fundImg: string
    title: string
  }
}

const DonationItem = ({ fundImgClass, detail }: DonationItemProps) => {
  const navigate = useNavigate()
  const kiosk = useStore((state) => state.kiosk)
  const setCauseSelected = useStore((state) => state.setCauseSelected)

  const { id, fundImg, title } = detail

  const selectCause = () => {
    setCauseSelected(id)
    trackEvent("Cause Selected", { props: { cause: title } })
    navigate(`/${kiosk}/cause-overview`)
  }

  return (
    <div
      onClick={selectCause}
      className='donation-item w-[878px] h-[144px] rounded-full bg-white flex justify-between items-center pl-[60px] mt-[50px] border border-slate-200'
    >
      <h1 className='text-[50px] font-[700] leading-[50px]'>{title}</h1>
      <img className={"w-[280px] mr-4 mt-1 " + fundImgClass} src={fundImg} alt={id} />
    </div>
  )
}

export default DonationItem
