import { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useIdleTimer } from "react-idle-timer"
import { db } from "../hooks/firebase"
import {
  doc,
  collection,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  Timestamp,
  increment,
} from "firebase/firestore"

import useStripe from "../hooks/useStripe"
import { useStore } from "../hooks/store"
import { fsColNames } from "../constants"
import { isValidCause, isValidEmail, getDonationIncrements } from "../utils/helpers"
import { cancelCollectPaymentMethod } from "../utils/stripe"

import GiftBox from "../assets/imgs/Gift_Box.png"
import PaymentModal, { PaymentModalType } from "../components/PaymentModal"
import { sendMessage } from "../utils/sendEmail"
import { trackEvent } from "../utils/analytics"

import TapCardImg from "../assets/imgs/tap-card.png"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)
const paymentIdleTimeout = Number(process.env.REACT_APP_PAYMENT_IDLE_TIMEOUT)

const HandlePayment = () => {
  const navigate = useNavigate()
  const kiosk = useStore((state) => state.kiosk)

  const causeSelected = useStore((state) => state.causeSelected)
  const donationAmount = useStore((state) => state.donationAmount)
  const donationEmail = useStore((state) => state.donationEmail)

  const [loading, setLoading] = useState<boolean>(false)
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false)
  const [paymentModalType, setPaymentModalType] = useState<PaymentModalType>(
    PaymentModalType.Loading,
  )
  const [paymentModalMessage, setPaymentModalMessage] = useState<string>("")

  const { state: stripeState, pay } = useStripe()

  useEffect(() => {
    console.log("pay data", { causeSelected, donationAmount, donationEmail })
    if (isValidCause(causeSelected) && donationAmount > 0) {
      processPayment()
    } else {
      navigate(`/${kiosk}`)
    }
  }, [causeSelected, donationAmount, donationEmail])

  // Idle redirect
  const idleTimeoutHandler = () => {
    console.log("Idle timeout")
    navigate(`/${kiosk}`)
  }

  const { start: startIdleTimer, pause: pauseIdleTimer } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler,
  })

  // Payment idle redirect
  const paymentIdleTimeoutHandler = () => {
    console.log(`Payment idle timeout: ${paymentIdleTimeout}ms elapsed`)

    cancelCollectPaymentMethod().catch((error) =>
      console.error("Cancel collect payment method failed", error),
    )
    idleTimeoutHandler()
  }

  const { start: startPaymentIdleTimer, pause: pausePaymentIdleTimer } = useIdleTimer({
    timeout: paymentIdleTimeout,
    onIdle: paymentIdleTimeoutHandler,
    // startManually: true,
  })

  useEffect(() => {
    let timeout: NodeJS.Timeout

    switch (stripeState.status) {
      case "tap":
        setPaymentModalType(PaymentModalType.TapCard)
        break

      case "success":
        pausePaymentIdleTimer()
        pauseIdleTimer()

        setPaymentModalType(PaymentModalType.Success)

        // store data to db
        storeIntoDB()

        // send email
        if (isValidEmail(donationEmail)) {
          sendEmail()
        }

        trackEvent("Donation Completed", {
          props: { causeSelected, donationAmount, donationEmail },
        })

        setTimeout(() => {
          setPaymentModalOpen(false)
        }, 3000)

        setTimeout(() => {
          navigate(`/${kiosk}/thank-you`)
        }, 4000)
        break

      case "error":
        if (stripeState.error == "AxiosError: Request failed with status code 500") {
          pausePaymentIdleTimer()
          pauseIdleTimer()

          setPaymentModalType(PaymentModalType.Success)

          // store data to db
          storeIntoDB()

          // send email
          if (isValidEmail(donationEmail)) {
            sendEmail()
          }

          trackEvent("Donation Completed", {
            props: { causeSelected, donationAmount, donationEmail },
          })

          setTimeout(() => {
            setPaymentModalOpen(false)
          }, 3000)

          setTimeout(() => {
            navigate(`/${kiosk}/thank-you`)
          }, 4000)
        } else {
          pausePaymentIdleTimer()
          startIdleTimer()

          setPaymentModalType(PaymentModalType.Error)
          setPaymentModalMessage(stripeState.error)

          trackEvent("Payment Error", { props: { error: stripeState.error } })

          timeout = setTimeout(() => {
            setPaymentModalOpen(false)
          }, 5000)
        }
        break

      case "loading":
        setPaymentModalType(PaymentModalType.Loading)
        break
    }

    return () => clearTimeout(timeout)
  }, [stripeState, navigate, donationAmount, pausePaymentIdleTimer, startIdleTimer, pauseIdleTimer])

  // send email
  const sendEmail = () => {
    const subject = "World Vision Canada thanks you"
    const textContent = "Thanks for donation!"
    const htmlContent = `
      <body style="margin: 0; padding: 0">
        <table align="center" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse">
          <tr>
            <td align="center" style="padding: 35px 0 30px 0">
              <img src="https://givingseason.ca/email/header_icon.png" alt="Creating Email Magic" />
            </td>
          </tr>
          <tr>
            <td>
              <img src="https://givingseason.ca/email/cover_img.png" alt="main" style="display: block" />
            </td>
          </tr>
          <tr>
            <td>
              <p style='color: #000000;
                    font-family: sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    padding: 25px 35px 50px;'>
                A huge THANK YOU for your generous donation towards life-changing gifts that support vulnerable
                children and families. Your gift will help us see a world where each child is safe, healthy and
                happy.
                <br /><br />
                We’ll connect with you again soon to share more on how your gift is making transformational impact
                possible. Look out for an update on how your donation has helped enrich lives across the globe.
              </p>
            </td>
          </tr>
          <tr>
            <td style="background-color: #cccccc;">
              <p style='color: #000000;
                    font-family: sans-serif;
                    font-size: 11px;
                    line-height: 18px;
                    text-align: left;
                    padding: 5px 35px 0px;'>
                World Vision is a Christian relief, development and advocacy organization working to create lasting
                change in the lives of children, families and communities to overcome poverty and injustice. Inspired by
                our Christian values, World Vision is dedicated to working with the world's most vulnerable people
                regardless of religion, race, ethnicity or gender.
                <br /><br />
                World Vision Canada | 1 World Drive, Mississauga Ontario Canada L5T 2Y4 | 1-866-595-5550
              </p>
            </td>
          </tr>
          <tr>
            <table align="center" cellpadding="0" cellspacing="0" width="639" style="border-collapse: collapse">
              <tbody>
                <tr>
                  <td style="background-color: #cccccc;">
                    <p style='color: #000000;
                          font-family: sans-serif;
                          font-size: 11px;
                          line-height: 18px;
                          text-align: left;
                          padding: 35px; padding-top: 10px;
                          padding-bottom: 10px;'>
      
                      <a href="https://www.worldvision.ca/privacy-and-security" target="_blank">Email Security</a> | <a
                        href="https://www.worldvision.ca/WorldVisionCanada/media/Safeguarding/World-Vision-Canada-External-Safeguarding-Policy.pdf"
                        target="_blank">Safeguarding</a>
                    </p>
                  </td>
                  <td style="background-color: #cccccc; text-align: right; padding-bottom: 10px; padding-right: 35px;">
                    <a target="_blank" href="https://www.facebook.com/WorldVisionCan/"><img style="width: 25px; margin-left: 5px;" src="https://givingseason.ca/email/facebook.png"
                        alt="facebook"></a>
                    <a target="_blank" href="https://twitter.com/worldvisioncan/"><img style="width: 25px; margin-left: 5px;" src="https://givingseason.ca/email/twitter.png"
                        alt="twitter"></a>
                    <a target="_blank" href="https://www.instagram.com/worldvisioncan/"><img style="width: 25px; margin-left: 5px;" src="https://givingseason.ca/email/instagram.png"
                        alt="instagram"></a>
                    <a target="_blank" href="https://www.youtube.com/WorldVisionCanada"><img style="width: 25px; margin-left: 5px;" src="https://givingseason.ca/email/youtube.png"
                        alt="youtube"></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </tr>
        </table>
      </body>
    `
    sendMessage(donationEmail, subject, textContent, htmlContent)
  }

  // process payment
  const processPayment = useCallback(async () => {
    pauseIdleTimer()
    startPaymentIdleTimer()

    setPaymentModalType(PaymentModalType.Loading)
    setPaymentModalOpen(true)

    // pay(50)
    pay(donationAmount)
  }, [pay, pauseIdleTimer, startPaymentIdleTimer])

  // process firestore data
  const storeIntoDB = async () => {
    console.log("called storeIntoDB")
    try {
      setLoading(true)
      // create a donation row
      await addDoc(collection(db, fsColNames.donations), {
        type: causeSelected,
        amount: donationAmount,
        email: donationEmail,
        createdAt: Timestamp.now(),
      })

      // create an email row
      const emailDocRef = doc(db, fsColNames.emails, donationEmail)
      await setDoc(emailDocRef, {
        lastDonationAmount: donationAmount,
        updatedAt: Timestamp.now(),
      })

      // create an analytics row
      const dnDocRef = doc(db, fsColNames.donationsAnalytics, causeSelected)
      const docSnap = await getDoc(dnDocRef)

      if (docSnap.exists()) {
        const daData = docSnap.data()

        const { totalGiftCountIncrement, donatedFundForOneGift } = getDonationIncrements(
          daData.donatedFundForOneGift,
          donationAmount,
          daData.targetFundForOneGift,
        )
        console.log({ totalGiftCountIncrement, donatedFundForOneGift })

        await updateDoc(dnDocRef, {
          donationCount: increment(1),
          totalGiftCount: increment(totalGiftCountIncrement),
          donatedFundForOneGift: donatedFundForOneGift,
          lastDonationAmount: donationAmount,
          updatedAt: Timestamp.now(),
        })
      }
      // else {
      //   const foundMeta = donationMetaData.find((md) => md.id == causeSelected)
      //   const { totalGiftCountIncrement, donatedFundForOneGift } = getDonationIncrements(
      //     0,
      //     donationAmount,
      //     foundMeta?.fundForOneGift,
      //   )
      //   console.log("No such document. foundMeta", foundMeta)
      //   console.log({ totalGiftCountIncrement, donatedFundForOneGift })

      //   await setDoc(dnDocRef, {
      //     order: foundMeta?.order,
      //     causeTitle: foundMeta?.causeTitle,
      //     targetFundForOneGift: foundMeta?.fundForOneGift,
      //     donationCount: 1,
      //     totalGiftCount: totalGiftCountIncrement,
      //     donatedFundForOneGift: donatedFundForOneGift,
      //     createdAt: Timestamp.now(),
      //     updatedAt: Timestamp.now(),
      //   })
      // }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='w-full h-full'>
      <div className='h-[270px] pt-[180px] px-[130px]'></div>
      <div className='h-[1650px] bg-[#ff6b00] p-[100px]'>
        <button
          // onClick={processPayment}
          type='button'
          className='focus:ring-4 focus:outline-none focus:ring-gray-300 absolute w-[85%] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-[white] rounded-full text-[65px] pl-[50px] py-8 text-left '
        >
          Tap your card
          <img
            className='absolute top-[-60px] right-[10px] w-[340px]'
            src={GiftBox}
            alt={"gift box"}
          />
        </button>

        <img src={TapCardImg} className='absolute top-[56%] ml-[30px] text-2xl' />
        {/* <p className='absolute top-[56%] ml-[30px] text-2xl'>
          Guaranteed <b>safe & secure</b> checkout. Powered by Stripe
        </p> */}
      </div>

      <PaymentModal open={paymentModalOpen} type={paymentModalType} message={paymentModalMessage} />
    </div>
  )
}

export default HandlePayment
