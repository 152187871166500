import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ScreenCard from "./ScreenCard"
import ScreenCardEnd from "./ScreenCardEnd"
// cover images
import CleanWaterBg from "../assets/imgs/clean-water-bg.png"
import BabyChicksBg from "../assets/imgs/baby-chicks-bg.png"
import EmergencyFoodBg from "../assets/imgs/emergency-food-bg.png"
import PigletBg from "../assets/imgs/piglet-bg.png"
import CropSeedsBg from "../assets/imgs/crop-seeds-bg.png"
import MedicalClinicBg from "../assets/imgs/medical_bg.png"
import EmpowerWomenBg from "../assets/imgs/women_bg.png"

// fund images
import CleanWaterFund from "../assets/imgs/Clean_Water_Fund.png"
import BabyChicksFund from "../assets/imgs/Baby_Chicks.png"
import EmergencyFoodBoxFund from "../assets/imgs/Emergency_Food_Box.png"
import PigletFund from "../assets/imgs/Piglet.png"
import FarmingToolsFund from "../assets/imgs/Farming_Tools.png"
import MedicalClinciFund from "../assets/imgs/medical_fund.png"
import EmpowerWomenFund from "../assets/imgs/women_fund.png"
import { donationIds } from "../constants"

const ScreenSaver = () => {
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000, // set 12000 to move in 5 seconds. it calculates total time = autoplaySpeed - speed
    arrows: false,
    dots: false,
    adaptiveHeight: true,
    pauseOnHover: false,
    pauseOnDotsHover: false,
  }

  const cards = {
    cleanWater: {
      id: donationIds.cleanWater,
      background: CleanWaterBg,
      fundImg: CleanWaterFund,
      title: "Give clean water",
      iseeText: (
        <div className='relative top-[130px] left-[130px]'>
          <p className='text-white text-[160px] leading-[140px]'>I see</p>
          <p className='text-white text-[60px] ml-[10px] leading-none'>water that is</p>
          <p className='text-white text-[60px] ml-[10px] leading-1'>clean</p>
        </div>
      ),
    },
    hensRoosters: {
      id: donationIds.hensRoosters,
      background: BabyChicksBg,
      fundImg: BabyChicksFund,
      title: "Give hens & roosters",
      iseeText: (
        <div className='relative top-[130px] left-[130px]'>
          <p className='text-white text-[160px] leading-[140px]'>I see</p>
          <p className='text-white text-[60px] ml-[10px] leading-none'>lives</p>
          <p className='text-white text-[60px] ml-[10px] leading-1'>transformed</p>
        </div>
      ),
    },
    emergencyFood: {
      id: donationIds.emergencyFood,
      background: EmergencyFoodBg,
      fundImg: EmergencyFoodBoxFund,
      title: "Give emergency food",
      iseeText: (
        <div className='relative top-[130px] left-[130px]'>
          <p className='text-white text-[160px] leading-[140px]'>I see</p>
          <p className='text-white text-[60px] ml-[10px] leading-none'>relief from</p>
          <p className='text-white text-[60px] ml-[10px] leading-1'>hunger</p>
        </div>
      ),
    },
    medicalClinic: {
      id: donationIds.medicalClinic,
      background: MedicalClinicBg,
      fundImg: MedicalClinciFund,
      title: "Stock a medical clinic",
      iseeText: (
        <div className='relative top-[130px] left-[130px]'>
          <p className='text-white text-[160px] leading-[140px]'>I see</p>
          <p className='text-white text-[60px] ml-[10px] leading-none'>healthy</p>
          <p className='text-white text-[60px] ml-[10px] leading-1'>families</p>
        </div>
      ),
    },
    empowerWomen: {
      id: donationIds.empowerWomen,
      background: EmpowerWomenBg,
      fundImg: EmpowerWomenFund,
      title: "Empower women entrepreneurs",
      iseeText: (
        <div className='relative top-[130px] left-[130px]'>
          <p className='text-white text-[160px] leading-[140px]'>I see</p>
          <p className='text-white text-[60px] ml-[10px] leading-none'>equality at</p>
          <p className='text-white text-[60px] ml-[10px] leading-1'>work</p>
        </div>
      ),
    },
  }

  return (
    <div className='slide-container m-auto w-full h-full'>
      <Slider {...settings}>
        <ScreenCard detail={cards.cleanWater} />
        <ScreenCard detail={cards.empowerWomen} />
        <ScreenCard detail={cards.emergencyFood} />
        <ScreenCard detail={cards.medicalClinic} />
        <ScreenCard detail={cards.hensRoosters} />
        <ScreenCardEnd />
      </Slider>
    </div>
  )
}

export default ScreenSaver
