import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import GiftProgress from "../components/GiftProgress"
// fund images
import CleanWaterFund from "../assets/imgs/Clean_Water_Fund.png"
import BabyChicksFund from "../assets/imgs/Baby_Chicks.png"
import EmergencyFoodBoxFund from "../assets/imgs/Emergency_Food_Box.png"
import PigletFund from "../assets/imgs/Piglet.png"
import FarmingToolsFund from "../assets/imgs/Farming_Tools.png"
import GiftBox from "../assets/imgs/Gift_Box.png"
import WomenFund from "../assets/imgs/women_fund.png"
import MedicalFund from "../assets/imgs/medical_fund.png"

import { useStore } from "../hooks/store"
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore"

import { db } from "../hooks/firebase"
import { donationMetaData, donationIds, fsColNames } from "../constants"
import { Modal, Spinner } from "flowbite-react"

import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"

import { trackEvent } from "../utils/analytics"
import { useIdleTimer } from "react-idle-timer"

const returnBtn = "Cancel"

const CauseOverview = () => {
  const navigate = useNavigate()
  const kiosk = useStore((state) => state.kiosk)

  const setDonationAmount = useStore((state) => state.setDonationAmount)
  const causeSelected = useStore((state) => state.causeSelected)

  const [analytics, setAnalytics] = useState<any>(null)
  const [progress, setProgress] = useState<any>(0)
  const [helpTarget, setHelpTarget] = useState<any>(0)
  const [topImg, setTopImg] = useState<any>(null)
  const [remainedAmount, setRemainedAmount] = useState<number>(0)
  const [customPrice, setCustomPrice] = useState<number>(0)
  const [showCustomAmountModal, setShowCustomAmountModal] = useState<boolean>(false)

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT),
    onIdle: () => {
      window.location.href = `/${kiosk}`
    },
  })

  useEffect(() => {
    if (causeSelected) {
      getAnalyticsData()
      getTopImg()
    }
  }, [causeSelected])

  const getTopImg = async () => {
    switch (causeSelected) {
      case donationIds.cleanWater:
        setTopImg(CleanWaterFund)
        break
      case donationIds.hensRoosters:
        setTopImg(BabyChicksFund)
        break
      case donationIds.emergencyFood:
        setTopImg(EmergencyFoodBoxFund)
        break
      case donationIds.empowerWomen:
        setTopImg(WomenFund)
        break
      case donationIds.medicalClinic:
        setTopImg(MedicalFund)
        break

      default:
        setTopImg(null)
        break
    }
  }

  const getDescription = () => {
    let htmlContent = <div></div>
    switch (causeSelected) {
      case donationIds.cleanWater:
        htmlContent = (
          <p className='text-[25px] text-center mt-[54px]'>
            Send water purification and hygiene kits to families in countries like Nicaragua and
            Somalia. Kits include water purification tablets, water filters and filtration units,
            soap, toothpaste, toothbrushes and more. A full Clean Water gift costs $25.
          </p>
        )
        break
      case donationIds.empowerWomen:
        htmlContent = (
          <p className='text-[25px] text-center mt-[54px]'>
            Help provide sewing machines, cloth, agricultural tools, business training and access to
            markets. Your gift will support women entrepreneurs in fragile countries like South
            Sudan. A full Empower Women Entrepreneurs gift costs $75.
          </p>
        )
        break
      case donationIds.emergencyFood:
        htmlContent = (
          <p className='text-[25px] text-center mt-[54px]'>
            Send nutritious meals to children and refugees. Your gift will support the urgent
            delivery of emergency food during humanitarian crises in countries such as Somalia and
            South Sudan. A full Emergency Food gift costs $25.
          </p>
        )
        break
      case donationIds.medicalClinic:
        htmlContent = (
          <p className='text-[25px] text-center mt-[30px]'>
            In vulnerable communities, clinic shelves are often empty. Help fill them with
            life-saving essentials like medical supplies, deworming drugs, gurneys, gloves, gowns,
            IVs and more. You’ll be sending supplies to clinics in countries like Somalia, Ethiopia,
            South Sudan and Burundi. A full Stock a Medical Clinic gift costs $100.
          </p>
        )
        break
      case donationIds.hensRoosters:
        htmlContent = (
          <p className='text-[25px] text-center mt-[54px]'>
            Your gift of hens and roosters will provide a source of income to families who sell the
            eggs at the market. What’s more, they’ll also provide nutrient-rich food for growing
            children in countries like Guatemala and El Salvador. A full Two Hens and a Rooster gift
            costs $50. Fill a coop for $100.
          </p>
        )
        break

      default:
        break
    }

    return htmlContent
  }

  const getAnalyticsData = async () => {
    const docRef = doc(db, fsColNames.donationsAnalytics, causeSelected)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()
      setAnalytics(data)
      setProgress(Math.round((data.donatedFundForOneGift / data.targetFundForOneGift) * 100))
      setHelpTarget(data.totalGiftCount + 1)
      setRemainedAmount(Math.ceil(data.targetFundForOneGift - data.donatedFundForOneGift))
    } else {
      const foundMeta = donationMetaData.find((md) => md.id == causeSelected)
      console.log("No such document. foundMeta", foundMeta)

      await setDoc(docRef, {
        order: foundMeta?.order,
        causeTitle: foundMeta?.causeTitle,
        overviewTitle: foundMeta?.overviewTitle,
        targetFundForOneGift: foundMeta?.fundForOneGift,
        donationCount: 0,
        totalGiftCount: 0,
        donatedFundForOneGift: 0,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      })

      getAnalyticsData()
    }
  }

  const setAmount = (amount: number) => {
    setDonationAmount(amount)
    console.log("setAmount", amount)
    trackEvent("Selected Donation Amount", { props: { amount } })
    navigate(`/${kiosk}/enter-email`)
  }

  const openCustomAmountModal = () => {
    setShowCustomAmountModal(true)
  }

  const onChange = (value: any) => {
    const number = +value // convert string to number
    setCustomPrice(number ? number : 0)
  }

  const onKeyReleased = (button: any) => {
    if (button == returnBtn) {
      setShowCustomAmountModal(false)
    } else if (button == "{enter}") {
      if (customPrice > 0) {
        setAmount(customPrice)
        setShowCustomAmountModal(false)
      }
    }
  }

  return (
    <>
      {analytics == null ? (
        <div
          role='status'
          className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
        >
          <Spinner
            aria-label='Extra large spinner example'
            className='w-[6rem!important] h-[6rem!important] fill-[#ff6b00]'
            size='xl'
          />
        </div>
      ) : (
        <div className='w-full h-full'>
          <div className='h-[953px] pt-[130px] px-[130px]'>
            {topImg && <img className='m-auto h-[325px]' src={topImg} />}
            <h3 className='mt-[40px] mr-[-100px] ml-[-100px] mb-0 text-[63px] font-black text-center'>
              {analytics?.overviewTitle}
            </h3>
            <p className='text-[27px] font-black text-center'>
              <b className='text-[#ff6b00]'>{analytics?.totalGiftCount}</b> full gifts sent so far -
              let{"'"}s make it
              <b className='text-[#ff6b00]'> {helpTarget}!</b>
            </p>
            <GiftProgress
              giftTarget={helpTarget}
              progressPercent={progress}
              wrapperClass='relative top-[10px]'
              pinClass='left-[750px!important]'
            />

            {getDescription()}
          </div>
          <div className='h-[967px] bg-[#ff6b00]'>
            <h1 className='text-[58px] text-center pt-[64px] text-white'>
              Give towards {analytics?.causeTitle}
            </h1>
            <div className='px-[135px] pt-[50px] flex flex-wrap justify-between'>
              <div
                onClick={() => setAmount(10)}
                className='mb-10 w-[380px] h-[250px] flex flex-wrap items-end justify-center text-center block max-w-sm p-6 bg-white border border-gray-200 rounded-[50px] shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'
              >
                <h5 className='leading-none text-[120px] relative left-[-20px] font-black tracking-tight'>
                  <sup>$</sup>10
                </h5>
                <p className='text-[24px] text-[#b04000]'>PUSH HERE TO DONATE</p>
              </div>

              <div
                onClick={() => setAmount(25)}
                className='w-[380px] h-[250px] flex flex-wrap items-end justify-center text-center block max-w-sm p-6 bg-white border border-gray-200 rounded-[50px] shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'
              >
                <h5 className='leading-none text-[120px] relative left-[-20px] font-black tracking-tight'>
                  <sup>$</sup>25
                </h5>
                <p className='text-[24px] text-[#b04000]'>PUSH HERE TO DONATE</p>
              </div>

              <div
                onClick={openCustomAmountModal}
                className='w-[380px] h-[250px] flex flex-wrap items-end justify-center text-center block max-w-sm p-6 bg-white border border-gray-200 rounded-[50px] shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'
              >
                <h5 className='leading-none text-[120px] relative left-[-20px] font-black tracking-tight'>
                  <sup>$</sup>XXX
                </h5>
                <p className='text-[34px] text-[#b04000]'>CUSTOM AMOUNT</p>
                <p className='text-[24px] text-[#b04000]'>PUSH HERE TO DONATE</p>
              </div>

              <div
                onClick={() => setAmount(remainedAmount)}
                className='w-[380px] h-[250px] flex flex-wrap items-end justify-center text-center block max-w-sm p-6 bg-white border border-gray-200 rounded-[50px] shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'
              >
                <h5 className='leading-none text-[120px] relative left-[-20px] font-black tracking-tight'>
                  <sup>$</sup>
                  {remainedAmount}
                </h5>
                <p className='text-[34px] text-[#b04000]'>GET US TO {helpTarget} GIFTS!</p>
                <p className='text-[24px] text-[#b04000]'>PUSH HERE TO DONATE</p>
              </div>

              <img
                className='absolute left-[50%] translate-x-[-50%] translate-y-[85%] w-[235px]'
                src={GiftBox}
                alt={"gift box"}
              />
            </div>
          </div>

          {/* Enter email modal */}
          <Modal size='4xl' show={showCustomAmountModal}>
            <Modal.Body className='pt-12 pb-12'>
              <input
                value={customPrice}
                readOnly
                className='text-[60px] mb-8 block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg sm:text-md p-4'
                type='number'
              />

              <Keyboard
                layoutName='default'
                onChange={onChange}
                disableButtonHold={true}
                onKeyReleased={onKeyReleased}
                stopMouseUpPropagation={true}
                stopMouseDownPropagation={true}
                layout={{
                  default: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp} " + returnBtn, "{enter}"],
                }}
                buttonTheme={[
                  {
                    class: "keyboard-button",
                    buttons: "1 2 3 4 5 6 7 8 9 0 . {bksp} {enter} " + returnBtn,
                  },
                ]}
                display={{
                  "{bksp}": "←",
                  "{enter}": "Donate",
                }}
                inputPattern={/^\d+$/}
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  )
}

export default CauseOverview
