import { donationIds } from "../constants"

export const isValidEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const isValidCause = (cause: string) => {
  let isValid = false

  Object.entries(donationIds).map((item) => {
    if (item[1] == cause) {
      isValid = true
    }
  })

  return isValid
}

export const getDonationIncrements = (existingAmount: any, newAmount: any, targetAmount: any) => {
  let totalGiftCountIncrement = 0
  let donatedFundForOneGift = existingAmount + newAmount

  if (donatedFundForOneGift >= targetAmount) {
    totalGiftCountIncrement = Math.floor(donatedFundForOneGift / targetAmount)
    donatedFundForOneGift = donatedFundForOneGift % targetAmount
  }

  return { totalGiftCountIncrement, donatedFundForOneGift }
}
