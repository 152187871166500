import {
  DiscoverResult,
  ErrorResponse,
  ExposedError,
  IPaymentIntent,
  ISdkManagedPaymentIntent,
  loadStripeTerminal,
  Terminal,
} from "@stripe/terminal-js"
import axios from "axios"
import Stripe from "stripe"

const readerConfig = { simulated: process.env.REACT_APP_PAYMENT_SIMULATED === "yes" }
console.log("readerConfig", readerConfig)

export async function fetchConnectionToken(): Promise<string> {
  const response = await axios.post("/api/connection_token")
  return response.data.secret
}

export async function createPaymentIntent(payload: {
  amount: number
  currency: string
}): Promise<string> {
  const response = await axios.post("/api/create_payment_intent", payload)
  return response.data.client_secret
}

export async function capturePaymentIntent(paymentIntentId: string): Promise<Stripe.PaymentIntent> {
  const response = await axios.post("/api/capture_payment_intent", {
    id: paymentIntentId,
  })
  return response.data
}

function onUnexpectedReaderDisconnect({ error }: { error?: ExposedError }) {
  console.error("Unexpected disconnect from reader", error)
  console.warn("Redirecting to home page in 10 seconds")
  const kioskLabel = window.location.pathname.split("/")[1]

  setTimeout(() => {
    window.location.href = kioskLabel ? `/${kioskLabel}` : "/not-found-onUnexpected"
  }, 10000)
}

// Singleton
export const createTerminalInstance = (() => {
  let terminal: Terminal

  async function create(): Promise<Terminal> {
    console.debug("Loading Stripe Terminal")

    const stripeTerminal = await loadStripeTerminal()

    console.debug("Loaded Stripe Terminal")

    if (stripeTerminal === null) {
      throw new Error("Terminal instance was not initialized")
    }

    console.debug("Creating Terminal instance")

    terminal = stripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect,
    })

    console.debug("Created Stripe Terminal")

    return terminal
  }

  return async () => {
    if (terminal) {
      return terminal
    }

    await create()

    return terminal
  }
})()

export async function discoverReaders(): Promise<Stripe.Terminal.Reader[]> {
  console.debug("Discovering readers")

  const terminal = await createTerminalInstance()
  const readersResult = await terminal!.discoverReaders(readerConfig)

  if ((readersResult as ErrorResponse).error) {
    console.error("Error discovering readers", (readersResult as ErrorResponse).error)
    throw new Error((readersResult as ErrorResponse).error.message)
  }

  const discoveredReaders = (readersResult as DiscoverResult).discoveredReaders

  console.debug("Discovered readers", discoveredReaders)

  return discoveredReaders
}

export async function connectReaderToTerminal(reader: Stripe.Terminal.Reader): Promise<void> {
  console.debug("Connecting reader to terminal", reader)

  const terminal = await createTerminalInstance()
  const readerConnectResult = await terminal!.connectReader(reader)

  if ((readerConnectResult as ErrorResponse).error) {
    console.error("Error connecting to reader", (readerConnectResult as ErrorResponse).error)
    throw new Error((readerConnectResult as ErrorResponse).error.message)
  }

  console.debug("Connected to reader")
}

export async function collectPaymentMethod(
  clientSecret: string,
): Promise<ISdkManagedPaymentIntent> {
  const terminal = await createTerminalInstance()
  const clientSecretResult = await terminal!.collectPaymentMethod(clientSecret)

  if ((clientSecretResult as ErrorResponse).error) {
    console.error("Error collecting payment method", (clientSecretResult as ErrorResponse).error)
    throw new Error((clientSecretResult as ErrorResponse).error.message)
  }

  const intent: ISdkManagedPaymentIntent = (clientSecretResult as any).paymentIntent

  return intent
}

export async function cancelCollectPaymentMethod(): Promise<void> {
  console.debug("Cancelling collect payment method")

  const terminal = await createTerminalInstance()
  const cancelResult = await terminal!.cancelCollectPaymentMethod()

  if ((cancelResult as ErrorResponse).error) {
    console.error("Cancelling failed", (cancelResult as ErrorResponse).error)
    throw new Error((cancelResult as ErrorResponse).error.message)
  }

  console.debug("Cancelled collect payment method")
}

export async function processPayment(intent: ISdkManagedPaymentIntent): Promise<IPaymentIntent> {
  const terminal = await createTerminalInstance()
  const result = await terminal!.processPayment(intent)

  if ((result as ErrorResponse).error) {
    console.error("Error processing payment", (result as ErrorResponse).error)
    throw new Error((result as ErrorResponse).error.message)
  }

  return (result as any).paymentIntent
}
