import create from "zustand"

type State = {
  causeSelected: any
  donationAmount: number
  donationEmail: any
  kiosk: any
  setCauseSelected: (data: string) => void
  setDonationAmount: (data: number) => void
  setDonationEmail: (data: string) => void
  setKiosk: (data: string) => void
}

export const useStore = create<State>((set) => ({
  causeSelected: null,
  donationAmount: 0,
  donationEmail: null,
  kiosk: null,
  setCauseSelected: (data) => set(() => ({ causeSelected: data })),
  setDonationAmount: (data) => set(() => ({ donationAmount: data })),
  setDonationEmail: (data) => set(() => ({ donationEmail: data })),
  setKiosk: (data) => set(() => ({ kiosk: data })),
}))
