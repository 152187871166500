import { useEffect } from "react"

interface GiftProgressProps {
  giftTarget: number
  progressPercent: number
  wrapperClass?: string
  pinClass?: string
}

const GiftProgress = ({
  giftTarget,
  progressPercent = 0,
  wrapperClass = "",
  pinClass = "",
}: GiftProgressProps) => {
  // useEffect(() => {

  // }, [])

  return (
    <div className={wrapperClass}>
      <div className={"pin2 " + pinClass}>
        <span
          className='text-[43px] font-black text-[#ff6b00] absolute top-[50%] left-[50%]'
          style={{ transform: "translate(-50%, -50%)" }}
        >
          {giftTarget}
        </span>
      </div>

      <div className='m-auto mt-8 h-[50px] bg-gray-200 rounded-full dark:bg-gray-700'>
        <div
          className='h-[50px] bg-[#f36b21] rounded-full dark:bg-[#f36b21]'
          style={{ width: progressPercent + "%", transition: ".7s all" }}
        ></div>
      </div>
    </div>
  )
}

export default GiftProgress
