import GiftProgress from "../components/GiftProgress"
import GetFundImgAsset from "../components/GetFundImgAsset"
import { useEffect, useState } from "react"
import { query, orderBy, collection, getDocs } from "firebase/firestore"
import { db } from "../hooks/firebase"
import { donationIds, fsColNames } from "../constants"
import { useStore } from "../hooks/store"

import { Spinner } from "flowbite-react"
import ConfettiExplosion from "react-confetti-explosion"

const ThankYou = () => {
  const kiosk = useStore((state) => state.kiosk)
  const causeSelected = useStore((state) => state.causeSelected)

  const [analyticsList, setAnalyticsList] = useState<any>(null)
  const [donatedNow, setDonatedNow] = useState<any>(null)
  const [increments, setIncrements] = useState<any>(null)
  const [incrementsFromDb, setIncrementsFromDb] = useState<any>(null)
  const [isExploding, setIsExploding] = useState(false)

  useEffect(() => {
    getAnalyticsData()
  }, [])

  useEffect(() => {
    let redirectTimeout: string | number | NodeJS.Timeout | undefined = undefined

    if (analyticsList != null) {
      redirectTimeout = setTimeout(() => {
        window.location.href = `/${kiosk}`
      }, 30000)

      // Make more delay until all components are loaded fully
      setTimeout(() => {
        if (causeSelected) {
          const incrementsFromDbData = {...incrementsFromDb}

          // if donated a full gift
          if (donatedNow.detail.totalGiftCount != 0 && donatedNow.detail.donatedFundForOneGift == 0) {
            // set 100% progress at first
            incrementsFromDbData[causeSelected] = donatedNow.detail.targetFundForOneGift
            
            // after 1.5s, set it back to zero
            // setTimeout(() => {
            //   const incrementsFromDbData = {...incrementsFromDb}
            //   incrementsFromDbData[causeSelected] = 0
            //   setIsExploding(false) // need to make sure confetti not showing again
            //   setIncrements({ ...incrementsFromDbData })
            // }, 1500)
          }

          // once page fully loaded, make progress animation
          setIncrements({ ...incrementsFromDbData })
        }

        // show confetti when page is fully loaded
        setIsExploding(true) 

      }, 100)
    }

    return () => {
      clearTimeout(redirectTimeout)
    }
  }, [analyticsList])

  const getAnalyticsData = async () => {
    const q = query(collection(db, fsColNames.donationsAnalytics), orderBy("order"))
    const querySnapshot = await getDocs(q)

    const docDatas: any = []
    const docIncreases: any = {}

    querySnapshot.forEach((doc) => {
      const daData = doc.data()

      // calculate increment by last donation amount
      let increment =
        (daData.lastDonationAmount ? daData.lastDonationAmount : 0) % daData.targetFundForOneGift

      // animate only after donated. 
      if (causeSelected != null && causeSelected == doc.id) {
        // analytics data donated just now
        setDonatedNow({
          id: doc.id,
          detail: daData,
        })

        // set initial progress to previous progress
        daData.progressBarValue = daData.donatedFundForOneGift - increment

        // if donated equal to or more than a full gift price, set initial progress to 0
        if (daData.donatedFundForOneGift == 0 || (daData.lastDonationAmount && daData.lastDonationAmount >= daData.targetFundForOneGift)) {
          daData.progressBarValue = 0
          increment = daData.donatedFundForOneGift
        }
      } else {
        // no animation on direct view
        daData.progressBarValue = daData.donatedFundForOneGift
        increment = 0
      }

      // prepare analytics list data
      docDatas.push({
        id: doc.id,
        detail: daData,
      })

      // prepare increment values for each cause
      docIncreases[doc.id] = increment
    })

    setAnalyticsList(docDatas)
    setIncrementsFromDb(docIncreases)
  }

  const getTitle = (causeSelected: string) => {
    let htmlContent = ""
    switch (causeSelected) {
      case donationIds.cleanWater:
        htmlContent = "Clean water"
        break
      case donationIds.empowerWomen:
        htmlContent = "Empower women entrepreneurs"
        break
      case donationIds.emergencyFood:
        htmlContent = "Emergency food"
        break
      case donationIds.medicalClinic:
        htmlContent = "Stock a medical clinic"
        break
      case donationIds.hensRoosters:
        htmlContent = "Hens & roosters"
        break

      default:
        break
    }

    return htmlContent
  }

  return (
    <>
      {isExploding && (
        <div className='absolute left-[510px] top-5'>
          <ConfettiExplosion width={1080} height={1920} />
        </div>
      )}
      {analyticsList == null ? (
        <div
          role='status'
          className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
        >
          <Spinner
            aria-label='Extra large spinner example'
            className='w-[6rem!important] h-[6rem!important] fill-[#ff6b00]'
            size='xl'
          />
        </div>
      ) : (
        <div className='w-full h-full'>
          <div className='h-[1372px] pt-[180px] pl-[40px] pr-[80px]'>
            {analyticsList.map((item: { id: string; detail: any }) => (
              <div key={item.id} className='flex mb-10 items-end'>
                <div className='w-[250px] mr-3'>
                  <img
                    className={`${item.id == donationIds.cleanWater ? "h-[195px]" : "h-[150px]"
                      } m-auto object-contain`}
                    src={GetFundImgAsset(item.id)}
                  />
                </div>
                <div className='w-full'>
                  <p className='text-[53px] leading-none mb-[14px]'>{getTitle(item.id)}</p>
                  <p className='relative top-[5px] text-[24px]'>
                    <b className='text-[#ff6b00]'>{item.detail?.totalGiftCount}</b> gifted to-date.
                    Help us make it{" "}
                    <b className='text-[#ff6b00]'> {item.detail?.totalGiftCount + 1}</b>!
                  </p>
                  <GiftProgress
                    giftTarget={item.detail?.totalGiftCount + 1}
                    progressPercent={Math.round(
                      ((item.detail?.progressBarValue +
                        (increments != null && increments[item.id] ? increments[item.id] : 0)) /
                        item.detail?.targetFundForOneGift) *
                      100,
                    )}
                    wrapperClass=''
                    pinClass='left-[930px!important]'
                  />
                </div>
              </div>
            ))}
          </div>
          <div className='h-[548px] bg-[#ff6b00] pt-[60px] text-center'>
            <p className='text-[142px] text-white leading-none'>Thank you</p>
            <p className='text-[33px] text-white'>
              We’ll send these life-changing gifts to families
            </p>
            <p className='text-[33px] text-white'>and children around the world.</p>
          </div>
        </div>
      )}
    </>
  )
}

export default ThankYou
