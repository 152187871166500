import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "flowbite"
import "./index.css"
import { enableAutoPageviews } from "./utils/analytics"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

enableAutoPageviews()

Sentry.init({
  dsn: "https://9fba457babd04edaa0fb233eab7ad64b@o1413651.ingest.sentry.io/4504339995885568",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>
)
