import DonationItem from "../components/DonationItem"
// fund images
import CleanWaterFund from "../assets/imgs/Clean_Water_SM.png"
import BabyChicksFund from "../assets/imgs/Baby_Chicks_SM.png"
import EmergencyFoodBoxFund from "../assets/imgs/Emergency_Food_Box_SM.png"
import EmpowerWomenFund from "../assets/imgs/women_fund_sm.png"
import MedicalClinciFund from "../assets/imgs/medical_fund_sm.png"
import { donationIds } from "../constants"
import { useIdleTimer } from "react-idle-timer"
import { useStore } from "../hooks/store"

const CauseSelection = () => {
  const kiosk = useStore((state) => state.kiosk)
  useIdleTimer({
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT),
    onIdle: () => {
      window.location.href = `/${kiosk}`
    },
  })

  const donationItems = [
    {
      id: donationIds.cleanWater,
      fundImg: CleanWaterFund,
      title: "Clean water",
    },
    {
      id: donationIds.empowerWomen,
      fundImg: EmpowerWomenFund,
      title: "Empower women entrepreneurs",
    },
    {
      id: donationIds.emergencyFood,
      fundImg: EmergencyFoodBoxFund,
      title: "Emergency food",
    },
    {
      id: donationIds.medicalClinic,
      fundImg: MedicalClinciFund,
      title: "Stock a medical clinic",
    },
    {
      id: donationIds.hensRoosters,
      fundImg: BabyChicksFund,
      title: "Hens & roosters",
    },
  ]

  return (
    <div className='w-full h-full'>
      <div className='h-[600px] pt-[180px] px-[80px]'>
        <h1 className='text-[110px]'>Choose a gift</h1>
        <p className='text-[35px]'>
          Send these life-changing gifts to communities around the world even to the most dangerous
          and hard-to-reach places. Your gift will help us see a world where each child is safe,
          healthy and happy.
        </p>
      </div>
      <div className='h-[1320px] bg-[#ff6b00]'>
        <div className='flex flex-wrap justify-center pt-[60px]'>
          {donationItems.map((item) => (
            <DonationItem
              key={item.id}
              fundImgClass={
                item.id == donationIds.empowerWomen
                  ? "mr-10 "
                  : "" + item.id == donationIds.emergencyFood
                  ? "mt-[-20px]"
                  : ""
              }
              detail={item}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CauseSelection
