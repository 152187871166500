export const donationIds = {
  cleanWater: "clean_water",
  hensRoosters: "hens_roosters",
  emergencyFood: "emergency_food",
  medicalClinic: "medical_clinic",
  empowerWomen: "empower_women",
}

export const donationMetaData = [
  {
    order: 1,
    id: donationIds.cleanWater,
    fundForOneGift: 25,
    causeTitle: "Clean water",
    overviewTitle: "Give clean water",
  },
  {
    order: 2,
    id: donationIds.empowerWomen,
    fundForOneGift: 75,
    causeTitle: "Empower Women",
    overviewTitle: "Empower women entrepreneurs",
  },
  {
    order: 3,
    id: donationIds.emergencyFood,
    fundForOneGift: 25,
    causeTitle: "Emergency food",
    overviewTitle: "Give emergency food",
  },
  {
    order: 4,
    id: donationIds.medicalClinic,
    fundForOneGift: 100,
    causeTitle: "Medical Clinic",
    overviewTitle: "Stock a medical clinic",
  },
  {
    order: 5,
    id: donationIds.hensRoosters,
    fundForOneGift: 50,
    causeTitle: "Hens & roosters",
    overviewTitle: "Hens & roosters",
  },
]

export const fsColNames = {
  donations: "donations",
  donationsAnalytics: "donation_analytics",
  emails: "emails",
}

export const kioskLabels = {
  kiosk1: "kiosk1",
  kiosk2: "kiosk2",
  kiosk3: "kiosk3",
}
