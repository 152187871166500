import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { useStore } from "../hooks/store"

import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import { isValidEmail } from "../utils/helpers"
import SendMsgBtn from "../assets/imgs/msg-btn.png"
import { trackEvent } from "../utils/analytics"
import { useIdleTimer } from "react-idle-timer"

const EnterEmail = () => {
  const navigate = useNavigate()
  const kiosk = useStore((state) => state.kiosk)

  const setDonationEmail = useStore((state) => state.setDonationEmail)

  const [emailEntered, setEmailEntered] = useState<string>("")
  const [keyLayoutName, setKeyLayoutName] = useState<string>("default")
  const [keyboardIns, setKeyboardIns] = useState<any>(null)

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT),
    onIdle: () => {
      window.location.href = `/${kiosk}`
    },
  })

  const handleSubmit = async () => {
    if (!isValidEmail(emailEntered)) {
      console.log("Email is not valid")
      return
    }

    setDonationEmail(emailEntered)
    trackEvent("Email Provided", { props: { emailEntered } })
    navigate(`/${kiosk}/handle-payment`)
  }

  const handleSkip = async () => {
    setDonationEmail("EmailNotProvided")
    trackEvent("Email Not Provided")
    navigate(`/${kiosk}/handle-payment`)
  }

  const onKeyboardInit = (keyboard: any) => {
    setKeyboardIns(keyboard)
  }
  const onChange = (value: any) => {
    setEmailEntered(value)
  }

  const onKeyPress = (button: any) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift()
    } else if (button === "{numbers}" || button === "{abc}") {
      handleNumbers()
    }

    let specialValue = null
    if (button === "{@}") {
      specialValue = emailEntered + "@"
    } else if (button === "{.}") {
      specialValue = emailEntered + "."
    } else if (button === "{-}") {
      specialValue = emailEntered + "-"
    } else if (button === "{_}") {
      specialValue = emailEntered + "_"
    } else if (button === "{.com}") {
      specialValue = emailEntered + ".com"
    } else if (button === "{☺}") {
      specialValue = emailEntered + "☺"
    }

    if (specialValue) {
      keyboardIns.setInput(specialValue)
      setEmailEntered(emailEntered + specialValue)
    }
  }

  const handleShift = () => {
    const currentLayout = keyLayoutName
    const shiftToggle = currentLayout === "default" ? "shift" : "default"

    setKeyLayoutName(shiftToggle)
  }

  const handleNumbers = () => {
    const currentLayout = keyLayoutName
    const numbersToggle = currentLayout !== "numbers" ? "numbers" : "default"

    setKeyLayoutName(numbersToggle)
  }

  return (
    <div className='w-full h-full'>
      <div className='h-[270px] pt-[180px] px-[130px]'></div>
      <div className='h-[1650px] bg-[#ff6b00] p-[100px]'>
        <div className='relative top-[40%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
          <div className=''>
            <label
              htmlFor='email-input'
              className='block text-center mb-10 text-[75px] font-medium text-white leading-[80px] ml-[-50px] mr-[-50px]'
            >
              Please enter your e-mail address to learn more about the impact you{"'"}ve made.
            </label>
            <div className='bg-white rounded-b-none rounded-[30px] px-[24px] py-[20px] flex items-center justify-center gap-2'>
              <input
                value={emailEntered}
                readOnly
                className='text-[#ff6b00] text-[50px] block w-full rounded-[50px] bg-[#e7e9ea] border-gray-300 focus:border-blue-500 focus:ring-blue-500 p-3'
                type='email'
              />
              <img src={SendMsgBtn} />
            </div>
          </div>

          <div className='email-keyboard-wrapper m-auto'>
            <Keyboard
              // keyboardRef={(r) => (keyboard.current = r)}
              layoutName={keyLayoutName}
              onChange={onChange}
              disableButtonHold={true}
              onKeyPress={onKeyPress}
              onInit={onKeyboardInit}
              stopMouseUpPropagation={true}
              stopMouseDownPropagation={true}
              layout={{
                default: [
                  "q w e r t y u i o p",
                  "a s d f g h j k l",
                  "{shift} z x c v b n m {backspace}",
                  "{numbers} {@} {-} {_} {=} {#} {+} {.} {.com}",
                ],
                shift: [
                  "Q W E R T Y U I O P",
                  "A S D F G H J K L",
                  "{shift} Z X C V B N M {backspace}",
                  "{numbers} {@} {-} {_} {=} {#} {+} {.} {.com}",
                ],
                numbers: ["1 2 3", "4 5 6", "7 8 9", "{abc} 0 {backspace}"],
              }}
              display={{
                "{numbers}": "123",
                "{ent}": "return",
                "{escape}": "esc ⎋",
                "{tab}": "tab ⇥",
                "{backspace}": "⌫",
                "{capslock}": "caps lock ⇪",
                "{shift}": "⇧",
                "{controlleft}": "ctrl ⌃",
                "{controlright}": "ctrl ⌃",
                "{altleft}": "alt ⌥",
                "{altright}": "alt ⌥",
                "{metaleft}": "cmd ⌘",
                "{metaright}": "cmd ⌘",
                "{abc}": "ABC",
                "{@}": "@",
                "{-}": "-",
                "{_}": "_",
                "{.}": ".",
                "{.com}": ".com",
                "{#}": "#",
                "{+}": "+",
                "{=}": "=",
                "{☺}": "☺",
                "{space}": "space",
                "{return}": "return",
              }}
            />
          </div>

          <p className='text-center text-white text-[23px] mt-[15px]'>
            By entering my e-mail address above I agree to be contacted by World Vision Canada.
          </p>

          <div className='text-center '>
            <button
              onClick={handleSubmit}
              type='button'
              className='text-[35px] mt-20 text-white bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg px-60 py-3.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800'
            >
              Next
            </button>
            <button
              onClick={handleSkip}
              type='button'
              className='text-[35px] mt-10 text-black focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg px-60 py-3.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800'
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnterEmail
