// fund images
import CleanWaterFund from "../assets/imgs/Clean_Water_Fund.png"
import BabyChicksFund from "../assets/imgs/Baby_Chicks.png"
import EmergencyFoodBoxFund from "../assets/imgs/Emergency_Food_Box.png"
import PigletFund from "../assets/imgs/Piglet.png"
import FarmingToolsFund from "../assets/imgs/Farming_Tools.png"
import MedicalFund from "../assets/imgs/medical_fund.png"
import WomenFund from "../assets/imgs/women_fund.png"
import { donationIds } from "../constants"

const GetFundImgAsset = (donationId = "", classString = "") => {
  let fundImg = ""
  switch (donationId) {
    case donationIds.cleanWater:
      fundImg = CleanWaterFund
      break
    case donationIds.hensRoosters:
      fundImg = BabyChicksFund
      break
    case donationIds.emergencyFood:
      fundImg = EmergencyFoodBoxFund
      break
    case donationIds.medicalClinic:
      fundImg = MedicalFund
      break
    case donationIds.empowerWomen:
      fundImg = WomenFund
      break

    default:
      fundImg = ""
      break
  }

  return fundImg
}

export default GetFundImgAsset
