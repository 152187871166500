import formData from "form-data"
import Mailgun from "mailgun.js"
const mailgun = new Mailgun(formData)
const mg = mailgun.client({ username: "api", key: String(process.env.REACT_APP_MAILGUN_API_KEY) })

export const sendMessage = async (
  toEmail: string,
  subject: string,
  textContent: string,
  htmlContent: string,
) => {
  mg.messages
    .create(String(process.env.REACT_APP_MAILGUN_DOMAIN), {
      from: "World Vision Canada <no-reply@givingseason.ca>",
      to: [toEmail],
      subject: subject,
      text: textContent,
      html: htmlContent,
    })
    .then((msg: any) => console.log({ msg }))
    .catch((err: any) => console.error({ err }))
}
