import { Link, useNavigate, useLocation, useParams } from "react-router-dom"
import BackArrowImg from "../../assets/imgs/back-arrow.png"
import { useStore } from "../../hooks/store"
const Footer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const kioskLabel = window.location.pathname.split("/")[1]
  const kioskLabel = useStore((state) => state.kiosk)

  if (
    location.pathname === "/" ||
    location.pathname == `/${kioskLabel}` ||
    location.pathname == `/${kioskLabel}/`
  ) {
    return <></>
  } else {
    return (
      <div>
        {location.pathname.indexOf('thank-you') === -1 && <button onClick={() => navigate(-1)} className='absolute left-[56px] top-[36px]'>
          <img src={BackArrowImg} />
        </button>}
        <Link to={`/${kioskLabel}`}>
          <button
            type='button'
            className='absolute right-[34px] top-[0px] px-14 py-3.5 text-[25px] text-white bg-[#221f1f] rounded-t-none rounded-lg text-center'
          >
            Home Screen
          </button>
        </Link>
        <Link to={`/${kioskLabel}`}>
          <button
            type='button'
            className='absolute right-[34px] bottom-[0px] px-14 py-3.5 text-[25px] text-white bg-[#221f1f] rounded-b-none rounded-lg text-center'
          >
            Home Screen
          </button>
        </Link>
      </div>
    )
  }
}

export default Footer
