import { useEffect } from "react"
import { Navigate, Route, Routes, useParams, useNavigate } from "react-router-dom"
import MasterLayout from "./layout/Master"
import Home from "./views/Home"
import CauseSelection from "./views/CauseSelection"
import CauseOverview from "./views/CauseOverview"
import EnterEmail from "./views/EnterEmail"
import HandlePayment from "./views/HandlePayment"
import ThankYou from "./views/ThankYou"
import { discoverReaders, connectReaderToTerminal } from "./utils/stripe"
import { kioskLabels } from "./constants"
import { useStore } from "./hooks/store"
import Login from "./views/Admin/Login"
import DonationReport from "./views/Admin/DonationReport"

const getTerminalId = (kiosk: string) => {
  let ternimalID: any = "notFound"

  switch (kiosk) {
    case kioskLabels.kiosk1:
      ternimalID = process.env.REACT_APP_KIOSK_1
      break
    case kioskLabels.kiosk2:
      ternimalID = process.env.REACT_APP_KIOSK_2
      break
    case kioskLabels.kiosk3:
      ternimalID = process.env.REACT_APP_KIOSK_3
      break

    default:
      break
  }

  console.log("ternimalID", ternimalID)
  return ternimalID
}

const Kiosk = () => {
  return (
    <MasterLayout>
      <Routes>
        <Route path='cause-selection' element={<CauseSelection />} />
        <Route path='cause-overview' element={<CauseOverview />} />
        <Route path='enter-email' element={<EnterEmail />} />
        <Route path='handle-payment' element={<HandlePayment />} />
        <Route path='thank-you' element={<ThankYou />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </MasterLayout>
  )
}

const App = () => {
  const setKioskParam = useStore((state) => state.setKiosk)

  useEffect(() => {
    const kioskLabel = window.location.pathname.split("/")[1]
    setKioskParam(kioskLabel ? kioskLabel : "kiosk-not-found")

    console.log("kioskLabel", kioskLabel)
    console.log("SIMULATED", process.env.REACT_APP_PAYMENT_SIMULATED)

    async function load() {
      try {
        const readers = await discoverReaders()
        const terminalID = getTerminalId(kioskLabel)

        let reader = readers.find((rr) => rr.id == terminalID)

        console.log("readers", readers, reader)

        if (process.env.REACT_APP_PAYMENT_SIMULATED === "yes") {
          reader = readers[0]
          setKioskParam("simulator")
        }

        if (reader == undefined) {
          throw new Error("Reader not found")
        }

        await connectReaderToTerminal(reader)
      } catch (error) {
        console.error("Initial Stripe comm error", error)

        if (process.env.NODE_ENV === "production") {
          console.warn("Redirecting to home page in 10 seconds")

          setTimeout(() => {
            window.location.href = kioskLabel ? `/${kioskLabel}` : "/"
          }, 10000)
        } else {
          console.warn("Ignoring error in development mode")
        }
      }
    }

    const excepts = ['login', 'donation-report']
    let needStripeReader = true
    excepts.map(item => { if (window.location.pathname.indexOf(item) > -1) { needStripeReader = false; } })

    // call load function immediately
    if(needStripeReader) {
      load()
    }
  }, [])

  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='donation-report' element={<DonationReport />} />
      <Route path='/' element={<Home />} />
      <Route path='/:kiosk/*' element={<Kiosk />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  )
}

export default App
